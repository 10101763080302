import { reactive, toRefs } from 'vue';
import { useSdk } from './use-mycure';

const state = reactive({
  icd10codes: [],
  cptCodes: [],
  countries: [],
});

export function useFixtures () {
  try {
    const sdk = useSdk();
    // const currentUser = ref(null);
    // const uid = computed(() => currentUser.value?.uid);

    const getDentalStatus = async () => {
      const payload = {
        type: 'dental-status',
      };
      const dentalStatus = await sdk.list('fixtures', payload);
      console.log('dentalStatus', dentalStatus);
      return dentalStatus.data;
    };

    const getICD10 = async (searchString) => {
      const payload = {
        type: 'icd10',
        // $search: {
        //   text: searchString,
        // },
        // $search: searchString,
        $sort: { createdAt: 1 },
      };
      // if (searchString) payload.$search = searchString;
      if (searchString) payload.text = { $regex: `^${searchString}`, $options: 'i' };
      const icd10 = await sdk?.list('fixtures', payload);
      state.icd10codes = icd10.data;
      return icd10.data;
    };

    const getCPTCodes = async (searchString) => {
      const payload = {
        type: 'cpt',
        $sort: { createdAt: 1 },
      };
      if (searchString) payload.name = { $regex: `^${searchString}`, $options: 'i' };
      const cpt = await sdk?.list('fixtures', payload);
      state.cptCodes = cpt.data;
      return cpt.data;
    };

    const getCountries = async (searchString, opts) => {
      const payload = {
        type: 'address-country',
        $sort: { name: 1 },
        ...opts,
      };
      if (searchString) payload.name = { $regex: `^${searchString}`, $options: 'i' };
      // if (searchString) {
      //   payload.name = searchString;
      //   payload.alpha2 = searchString;
      // };
      const countries = await sdk?.list('fixtures', payload);
      state.countries = countries.data;
      return countries.data;
    };

    const findOneFixture = async (data, type) => {
      const payload = {
        type,
        ...data,
      };
      console.warn('payload', payload); 
      const fixture = await sdk?.get('fixtures', payload);
      console.warn('fixture', fixture);
      return fixture;
    };

    return {
      ...toRefs(state),
      getDentalStatus,
      getICD10,
      getCPTCodes,
      getCountries,
      findOneFixture,
    };
  } catch (e) {
    console.error(e);
  }
}
